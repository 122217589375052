import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'



export const getMerchantProfileContent = createAsyncThunk('/merchant/profile/content', async () => {
    // const response = await axios.get('/api/users?page=2', {})
    // console.log('response: ', response);
    // return response.data;

    return {
        email: "qG6oN@example.com",
        merchant_name: "Merchant Name"
    };
})

export const merchantProfileSlice = createSlice({
    name: 'merchantProfile',
    initialState: {
        isLoading: false,
        email: "",
        merchantName: "",
        error: null,
    },
    reducers: {

        updateProfileSettings: (state) => {
            state.error = null
            // TODO: call api
            console.log('[1] state merchantName: ', state.merchantName)
            if (state.merchantName.trim() === "") {
                state.error = "Merchant Name is required!"
                return;
            }
        },
        updateField(state, action) {
            console.log('[1] action payload: ', action.payload)

            const { updateType, value } = action.payload;
            return {
                ...state,
                [updateType]: value
            }
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(getMerchantProfileContent.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getMerchantProfileContent.fulfilled, (state, action) => {
                console.log("payload data: ", action);
                state.email = action.payload.email
                state.merchantName = action.payload.merchant_name
                state.isLoading = false
            })
            .addCase(getMerchantProfileContent.rejected, state => {
                state.isLoading = false
            })
    }
})

export const { updateProfileSettings, updateField } = merchantProfileSlice.actions

export default merchantProfileSlice.reducer