import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import { PROGRAM_DUMMIES } from '../../../utils/dummyData';
import programApiService from '../../../api/program/programApiService';
import { showNotification } from '../../common/headerSlice';


export const getProgramsContent = createAsyncThunk('/programs/content', async ({ page, pageSize }) => {

    console.log('getProgramsContent page: ', page, 'pageSize: ', pageSize)

    const mid = localStorage.getItem("mid");

    try {
        const resp = await programApiService.getPage(mid, page, pageSize);
        console.log('getProgramsContent resp: ', resp)
        return resp

        //return PROGRAM_DUMMIES;
    } catch (error) {
        console.error('getProgramsContent error: ', error)
        throw error;
    }
})

export const addProgramAsync = createAsyncThunk('/programs/add/', async ({ newProgramObj, rewards }, { dispatch }) => {

    console.log('addProgramAsync')

    const mid = localStorage.getItem("mid");

    try {
        newProgramObj.rewards = rewards

        // success response will return the program id
        const pid = await programApiService.add(mid, newProgramObj);
        console.log('addProgramAsync resp: ', pid)
        newProgramObj.program_id = pid

        dispatch(showNotification({ message: "New Program Added!", status: 1 }))
        return newProgramObj;

    } catch (error) {
        console.error('addProgramAsync error: ', error)

        dispatch(showNotification({ message: "Failed to add program", status: 0 }))
        throw error;
    }
})

export const updateProgramAsync = createAsyncThunk('/programs/update/', async ({ programObj, rewards }, { dispatch }) => {

    console.log('updateProgramAsync')

    const mid = localStorage.getItem("mid");

    try {
        const updatedProgramObj = { ...programObj };
        updatedProgramObj.rewards = rewards

        await programApiService.update(mid, updatedProgramObj);

        dispatch(showNotification({ message: "Program Updated!", status: 1 }));
        return updatedProgramObj;

    } catch (error) {
        console.error('updateProgramAsync error: ', error)

        dispatch(showNotification({ message: "Failed to update program", status: 0 }));
        throw error;
    }
})

export const deleteProgramAsync = createAsyncThunk('/programs/delete/', async ({ pid }, { dispatch }) => {

    console.log('deleteProgramAsync')

    const mid = localStorage.getItem("mid");

    try {
        // success response will return the program id
        await programApiService.delete(mid, pid);

        dispatch(showNotification({ message: "Program Deleted!", status: 1 }));
        return { success: true, resp: pid };

    } catch (error) {
        console.error('deleteProgramAsync error: ', error)

        dispatch(showNotification({ message: "Failed to delete program", status: 0 }));
        throw error;
    }
})

export const programSlice = createSlice({
    name: 'programs',
    initialState: {
        isLoading: false,
        programPaginate: {
            programs: [],
            totalPrograms: 0,
            totalPages: 0,
        },
        error: null,
    },
    reducers: {

        addNewProgram: (state, action) => {
            console.log("add new program: ", action.payload)
            let { newProgramObj } = action.payload
            state.programPaginate.programs.push(newProgramObj)
            state.programPaginate.totalPrograms = state.programPaginate.totalPrograms + 1
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(getProgramsContent.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getProgramsContent.fulfilled, (state, action) => {
                console.log("payload data: ", action);
                state.programPaginate = {
                    programs: action.payload.programs,
                    totalPrograms: action.payload.total_elements,
                    totalPages: action.payload.total_pages
                }
                state.isLoading = false
            })
            .addCase(getProgramsContent.rejected, state => {
                state.isLoading = false
            })
            .addCase(addProgramAsync.pending, (state) => {
                state.isLoading = true
            })
            .addCase(addProgramAsync.fulfilled, (state, action) => {
                console.log("payload data: ", action);

                // 1. Clone the existing program list to avoid mutation:
                const updatedPrograms = [...state.programPaginate.programs];

                const newProgram = action.payload

                // 2. Add the new program to the cloned list:
                updatedPrograms.push(newProgram);

                // 3. Sort the updated list by start date (descending order):
                updatedPrograms.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));

                // 4. Update state with the modified program list and total count:
                return {
                    ...state,
                    programPaginate: {
                        ...state.programPaginate,
                        programs: updatedPrograms,
                        totalPrograms: state.programPaginate.totalPrograms + 1,
                    },
                    isLoading: false,
                };
            })
            .addCase(addProgramAsync.rejected, state => {
                state.isLoading = false
            })
            .addCase(updateProgramAsync.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateProgramAsync.fulfilled, (state, action) => {
                console.log("payload data: ", action);
                state.programPaginate.programs = state.programPaginate.programs.map(program => {
                    if (program.program_id === action.payload.program_id) {
                        return action.payload
                    }
                    return program
                })
                state.isLoading = false
            })
            .addCase(updateProgramAsync.rejected, state => {
                state.isLoading = false
            })
            .addCase(deleteProgramAsync.pending, (state) => {
                state.isLoading = true
            })
            .addCase(deleteProgramAsync.fulfilled, (state, action) => {
                console.log("payload data: ", action);
                state.programPaginate.programs = state.programPaginate.programs.filter(program => program.program_id !== action.payload.resp)
                state.programPaginate.totalPrograms = state.programPaginate.totalPrograms - 1
                state.isLoading = false
            })
            .addCase(deleteProgramAsync.rejected, state => {
                state.isLoading = false
            })
    }
})

export const { addNewProgram, deleteProgram } = programSlice.actions

export default programSlice.reducer