
const API_URLS = {
    auth: {
        merchLogin: '/merchant/login',
        merchSignUp: '/merchant/signup'
    },
    members: {
        getAllMembersByMerchant: '/members',
        getPageByMerchant: '/members/paginate',
        getMemberById: (userId) => `/member/${userId}`
    },
    transaction: {
        getPageByMerchant: '/transactions/paginate',
        getTransactionInfoByTxnRefNo: (mid, txnRefNo) => `/transactions/transaction/info?mid=${mid}&transaction_ref_no=${txnRefNo}`
    },
    program: {
        getPageByMerchant: '/programs/paginate',
        add: '/programs/add',
        delete: '/programs/program/delete',
        update: '/programs/program/update'
    },
    products: {
        getAllProducts: '/products',
        getProductById: (productId) => `/products/${productId}`
    },
    metrics: {
        get: (mid) => `/metrics?mid=${mid}`,
    }
};

export default API_URLS;
