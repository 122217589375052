import API_URLS from "../apiConstants";
import axiosInstance from "../axiosInstance"; 

const metricsApiService = {
  get: async (mid) => {
    try {
      const response = await axiosInstance.get(API_URLS.metrics.get(mid));
      return response.data;
    } catch (error) {
      console.error('Error retrieving txn:', error);
      throw error;
    }
  },
}

export default metricsApiService;