import API_URLS from "../apiConstants";
import axiosInstance from "../axiosInstance";
import _ from 'lodash';

const transactionApiService = {
  getPage: async (mid, pageNumber, pageSize) => {
    try {
      const requestBody = _.mapKeys({ mid, pageNumber, pageSize }, (value, key) => _.snakeCase(key));

      const response = await axiosInstance.post(API_URLS.transaction.getPageByMerchant, requestBody);
      return response.data;
    } catch (error) {
      console.error('Error retrieving txns:', error);
      throw error;
    }
  },
  getTransactionInfo: async (mid, txnRefNo) => {
    try {
      const response = await axiosInstance.get(API_URLS.transaction.getTransactionInfoByTxnRefNo(mid, txnRefNo));
      return response.data;
    } catch (error) {
      console.error('Error retrieving txn:', error);
      throw error;
    }
  }
}

export default transactionApiService;