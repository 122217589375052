import API_URLS from "../apiConstants";
import axiosInstance from "../axiosInstance";
import _ from 'lodash';

const memberApiService = {
  getPage: async (mid, pageNumber, pageSize) => {
    try {
      const requestBody = _.mapKeys({ mid, pageNumber, pageSize }, (value, key) => _.snakeCase(key));

      const response = await axiosInstance.post(API_URLS.members.getPageByMerchant, requestBody);
      return response.data;
    } catch (error) {
      console.error('Error registering user:', error);
      throw error;
    }
  }
}

export default memberApiService;