import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import metricsApiService from '../../../api/metrics/metricsApiService';
import { METRICS_DUMMIES } from '../../../utils/dummyData';


export const getDashboardContent = createAsyncThunk('/dashboard/content', async () => {

    const mid = localStorage.getItem("mid");

    try {
        const resp = await metricsApiService.get(mid);
        console.log('getDashboardContent resp: ', resp)
        return resp

        //return METRICS_DUMMIES;
    } catch (error) {
        console.error('getDashboardContent error: ', error)
        throw error;
    }
})

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        isLoading: false,
        members: {
            total: 0,
            newThisMonth: 0
        },
        points: {
            issued: 0,
            redeemed: 0
        },
        transactions: {
            total: 0,
            averageAmount: 0
        },
        error: null,
    },
    reducers: {},

    extraReducers: (builder) => {

        builder
            .addCase(getDashboardContent.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getDashboardContent.fulfilled, (state, action) => {
                console.log("metrics payload data: ", action);
                state.members = {
                    ...state.members,
                    total: action.payload.members.total || 0,
                    newThisMonth: action.payload.members.new_this_month || 0
                };
                state.points = {
                    ...state.points,
                    issued: action.payload.points.issued || 0,
                    redeemed: action.payload.points.redeemed || 0
                }
                state.transactions = {
                    ...state.transactions,
                    total: action.payload.transactions.total || 0,
                    averageAmount: action.payload.transactions.average_amount || 0
                }
                state.isLoading = false
            })
            .addCase(getDashboardContent.rejected, (state) => {
                state.isLoading = false
            })
    }
})

export default dashboardSlice.reducer