import API_URLS from "../apiConstants";
import axiosInstance from "../axiosInstance";
import _ from 'lodash';

const programApiService = {
  getPage: async (mid, pageNumber, pageSize) => {
    try {
      const requestBody = _.mapKeys({ mid, pageNumber, pageSize }, (value, key) => _.snakeCase(key));

      const response = await axiosInstance.post(API_URLS.program.getPageByMerchant, requestBody);
      return response.data;
    } catch (error) {
      console.error('Error retrieving programs:', error);
      throw error;
    }
  },
  add: async (mid, program) => {
    try {
      console.log('add program: ', program)
      const requestBody = _.mapKeys({ mid, program }, (value, key) => _.snakeCase(key));

      console.log('requestBody: ', requestBody)

      const response = await axiosInstance.post(API_URLS.program.add, requestBody);
      return response.data;
    } catch (error) {
      console.error('Error creating program:', error);
      throw error;
    }
  },
  delete: async (mid, program_id) => {
    try {
      const requestBody = _.mapKeys({ mid, program_id }, (value, key) => _.snakeCase(key));

      const response = await axiosInstance.post(API_URLS.program.delete, requestBody);
      return response.data;
    } catch (error) {
      console.error('Error deleting program:', error);
      throw error;
    }
  },
  update: async (mid, program) => {
    try {
      const requestBody = _.mapKeys({ mid, program }, (value, key) => _.snakeCase(key));
      const response = await axiosInstance.post(API_URLS.program.update, requestBody);
      return response.data;
    } catch (error) {
      console.error('Error updating program:', error);
      throw error;
    }
  }
}

export default programApiService;