import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { MEMBER_DUMMIES } from '../../utils/dummyData'
import { act } from 'react-dom/test-utils';
import memberApiService from '../../api/member/memberApiService';



export const getMembersContent = createAsyncThunk('/members/content', async ({ page, pageSize }) => {
    console.log('getMembersContent')

    const mid = localStorage.getItem("mid");

    try {
        const resp = await memberApiService.getPage(mid, page, pageSize);
        console.log('getMembersContent resp: ', resp)
        return resp
        //return MEMBER_DUMMIES;
    } catch (error) {
        console.error('getMembersContent error: ', error)
        throw error;
    }
})

export const membersSlice = createSlice({
    name: 'members',
    initialState: {
        isLoading: false,
        members: [],
        totalMembers: 0,
        currentPage: 0,
        totalPages: 0,
        pageSize: 10,
    },
    reducers: {
        deleteMember: (state, action) => {
            let { index } = action.payload
            state.members.splice(index, 1)
        },
        updateMemberPageSize: (state, action) => {
            if (action.payload >= 0 || action.payload <= state.totalPages) {
                state.pageSize = action.payload
            }
        },
        updateMemberPageNumber: (state, action) => {
            state.currentPage = action.payload
        }
    },

    extraReducers: (builder) => {

        builder
            .addCase(getMembersContent.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getMembersContent.fulfilled, (state, action) => {
                console.log("payload data: ", action);
                state.members = action.payload.members;
                state.totalMembers = action.payload.total_elements || 0;
                state.totalPages = action.payload.total_pages || 0;
                state.isLoading = false
            })
            .addCase(getMembersContent.rejected, state => {
                state.isLoading = false
            })

    }
})

export const { deleteMember, updateMemberPageSize, updateMemberPageNumber } = membersSlice.actions

export default membersSlice.reducer