import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { TXN_REWARD_INFO_DUMMY } from '../../../utils/dummyData'
import transactionApiService from '../../../api/transaction/transactionApiService';



export const getTransactionRewardInfoContent = createAsyncThunk('/transaction/rewardInfoContent', async ({ mid, txnRefNo }) => {

    console.log('getTransactionRewardInfoContent')

    try {
        return await transactionApiService.getTransactionInfo(mid, txnRefNo);

        //return TXN_REWARD_INFO_DUMMY;
    } catch (error) {
        console.error('getTransactionRewardInfoContent error: ', error)
        throw error;
    }
})

export const transactionRewardInfoSlice = createSlice({
    name: 'transactionRewardInfo',
    initialState: {
        isLoading: false,
        txnRewardInfo: null,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTransactionRewardInfoContent.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getTransactionRewardInfoContent.fulfilled, (state, action) => {
                state.txnRewardInfo = action.payload.transaction_info;
                state.isLoading = false
            })
            .addCase(getTransactionRewardInfoContent.rejected, state => {
                state.isLoading = false
            })
    }
})

export default transactionRewardInfoSlice.reducer