import React, { lazy, useEffect } from 'react'
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { themeChange } from 'theme-change'
import checkAuth from './app/auth';
import initializeApp from './app/init';

// Importing pages
const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))
const SignUp = lazy(() => import('./pages/SignUp'))
const Documentation = lazy(() => import('./pages/Documentation'))
const TermsAndCondition = lazy(() => import('./pages/TermsAndCondition'))
const PrivacyNotice = lazy(() => import('./pages/PrivacyNotice'))


// Initializing different libraries
initializeApp()


// Check for login and initialize axios
const token = checkAuth()


function App() {

  useEffect(() => {
    // 👆 daisy UI themes initialization
    themeChange(false)
  }, [])


  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/documentation" element={<Documentation />} />
          <Route path="/doc/terms-and-conditions" element={<TermsAndCondition />} />
          <Route path="/doc/privacy-notice" element={<PrivacyNotice />} />

          {/* Place new routes over this */}
          <Route path="/*" element={<Layout />} />

          <Route path="*" element={<Navigate to={token ? "/dashboard" : "/login"} replace />} />

        </Routes>
      </Router>
    </>
  )
}

export default App
