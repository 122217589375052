import axios from "axios"

const initializeApp = () => {

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // dev code
        // Setting base URL for all API request via axios
        axios.defaults.baseURL = process.env.REACT_APP_LOYALTY_DEV_BASE_URL


    } else {
        // Prod build code
        // Setting base URL for all API request via axios
        axios.defaults.baseURL = process.env.REACT_APP_LOYALTY_PROD_BASE_URL


        // Removing console.log from prod
        console.log = () => { };


        // init analytics here
    }

}

export default initializeApp