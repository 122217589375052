import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import { DUMMY_TRANSACTIONS } from '../../utils/dummyData'
import transactionApiService from '../../api/transaction/transactionApiService';


export const getTransactionsContent = createAsyncThunk('/transactions/content', async ({ page, pageSize }) => {

    console.log('getTransactionsContent')

    const mid = localStorage.getItem("mid");

    try {
        const resp = await transactionApiService.getPage(mid, page, pageSize);
        return resp

        //return DUMMY_TRANSACTIONS;
    } catch (error) {
        console.error('getTransactionsContent error: ', error)
        throw error;
    }
})

export const transactionsSlice = createSlice({
    name: 'transactions',
    initialState: {
        isLoading: false,
        transactions: [],
        totalTransactions: 0,
        totalPages: 0,
        error: null,
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTransactionsContent.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getTransactionsContent.fulfilled, (state, action) => {
                console.log("payload data: ", action);
                state.transactions = action.payload.reward_transactions;
                state.totalTransactions = action.payload.total_elements;
                state.totalPages = action.payload.total_pages;
                state.isLoading = false
            })
            .addCase(getTransactionsContent.rejected, state => {
                state.isLoading = false
            })

    }
})

export default transactionsSlice.reducer