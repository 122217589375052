// apiService.js
import axios from 'axios';

const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json'
    // Add any other default headers here
  }
});

// Add interceptor to handle response errors globally
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('API request error:', error);
    throw error; // Re-throw the error to handle it where it's being called
  }
);

export default axiosInstance;
