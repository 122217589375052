const moment = require("moment");

module.exports = Object.freeze({
    CALENDAR_INITIAL_EVENTS: [
        { title: "Product call", theme: "GREEN", startTime: moment().add(-12, 'd').startOf('day'), endTime: moment().add(-12, 'd').endOf('day') },
        { title: "Meeting with tech team", theme: "PINK", startTime: moment().add(-8, 'd').startOf('day'), endTime: moment().add(-8, 'd').endOf('day') },
        { title: "Meeting with Cristina", theme: "PURPLE", startTime: moment().add(-2, 'd').startOf('day'), endTime: moment().add(-2, 'd').endOf('day') },
        { title: "Meeting with Alex", theme: "BLUE", startTime: moment().startOf('day'), endTime: moment().endOf('day') },
        { title: "Product Call", theme: "GREEN", startTime: moment().startOf('day'), endTime: moment().endOf('day') },
        { title: "Client Meeting", theme: "PURPLE", startTime: moment().startOf('day'), endTime: moment().endOf('day') },
        { title: "Client Meeting", theme: "ORANGE", startTime: moment().add(3, 'd').startOf('day'), endTime: moment().add(3, 'd').endOf('day') },
        { title: "Product meeting", theme: "PINK", startTime: moment().add(5, 'd').startOf('day'), endTime: moment().add(5, 'd').endOf('day') },
        { title: "Sales Meeting", theme: "GREEN", startTime: moment().add(8, 'd').startOf('day'), endTime: moment().add(8, 'd').endOf('day') },
        { title: "Product Meeting", theme: "ORANGE", startTime: moment().add(8, 'd').startOf('day'), endTime: moment().add(8, 'd').endOf('day') },
        { title: "Marketing Meeting", theme: "PINK", startTime: moment().add(8, 'd').startOf('day'), endTime: moment().add(8, 'd').endOf('day') },
        { title: "Client Meeting", theme: "GREEN", startTime: moment().add(8, 'd').startOf('day'), endTime: moment().add(8, 'd').endOf('day') },
        { title: "Sales meeting", theme: "BLUE", startTime: moment().add(12, 'd').startOf('day'), endTime: moment().add(12, 'd').endOf('day') },
        { title: "Client meeting", theme: "PURPLE", startTime: moment().add(16, 'd').startOf('day'), endTime: moment().add(16, 'd').endOf('day') },
    ],
    DUMMY_TRANSACTIONS: {
        page: 1,
        data: [
            { id: 105, mobileNo: "88000233", amount: 1500, transactionDateTime: "2024-03-02 12:35:45" },
            { id: 104, mobileNo: "88000233", amount: 1500, transactionDateTime: "2024-03-02 12:33:45" },
            { id: 103, mobileNo: "88000233", amount: 1500, transactionDateTime: "2024-03-02 12:32:45" },
            { id: 102, mobileNo: "88000233", amount: 1500, transactionDateTime: "2024-03-02 12:31:45" },
        ]
    },
    RECENT_TRANSACTIONS: [
        { name: "Alex", avatar: "https://reqres.in/img/faces/1-image.jpg", email: "alex@dashwind.com", location: "Paris", amount: 100, date: moment().endOf('day') },
        { name: "Ereena", avatar: "https://reqres.in/img/faces/2-image.jpg", email: "ereena@dashwind.com", location: "London", amount: 190, date: moment().add(-1, 'd').endOf('day') },
        { name: "John", avatar: "https://reqres.in/img/faces/3-image.jpg", email: "jhon@dashwind.com", location: "Canada", amount: 112, date: moment().add(-1, 'd').endOf('day') },
        { name: "Matrix", avatar: "https://reqres.in/img/faces/4-image.jpg", email: "matrix@dashwind.com", location: "Peru", amount: 111, date: moment().add(-1, 'd').endOf('day') },
        { name: "Virat", avatar: "https://reqres.in/img/faces/5-image.jpg", email: "virat@dashwind.com", location: "London", amount: 190, date: moment().add(-2, 'd').endOf('day') },
        { name: "Miya", avatar: "https://reqres.in/img/faces/6-image.jpg", email: "miya@dashwind.com", location: "Paris", amount: 230, date: moment().add(-2, 'd').endOf('day') },
        { name: "Virat", avatar: "https://reqres.in/img/faces/3-image.jpg", email: "virat@dashwind.com", location: "Canada", amount: 331, date: moment().add(-2, 'd').endOf('day') },
        { name: "Matrix", avatar: "https://reqres.in/img/faces/1-image.jpg", email: "matrix@dashwind.com", location: "London", amount: 581, date: moment().add(-2, 'd').endOf('day') },
        { name: "Ereena", avatar: "https://reqres.in/img/faces/3-image.jpg", email: "ereena@dashwind.com", location: "Tokyo", amount: 151, date: moment().add(-2, 'd').endOf('day') },
        { name: "John", avatar: "https://reqres.in/img/faces/2-image.jpg", email: "jhon@dashwind.com", location: "Paris", amount: 91, date: moment().add(-2, 'd').endOf('day') },
        { name: "Virat", avatar: "https://reqres.in/img/faces/3-image.jpg", email: "virat@dashwind.com", location: "Canada", amount: 161, date: moment().add(-3, 'd').endOf('day') },
        { name: "Matrix", avatar: "https://reqres.in/img/faces/4-image.jpg", email: "matrix@dashwind.com", location: "US", amount: 121, date: moment().add(-3, 'd').endOf('day') },
        { name: "Ereena", avatar: "https://reqres.in/img/faces/6-image.jpg", email: "jhon@dashwind.com", location: "Tokyo", amount: 713, date: moment().add(-3, 'd').endOf('day') },
        { name: "John", avatar: "https://reqres.in/img/faces/2-image.jpg", email: "ereena@dashwind.com", location: "London", amount: 217, date: moment().add(-3, 'd').endOf('day') },
        { name: "Virat", avatar: "https://reqres.in/img/faces/3-image.jpg", email: "virat@dashwind.com", location: "Paris", amount: 117, date: moment().add(-3, 'd').endOf('day') },
        { name: "Miya", avatar: "https://reqres.in/img/faces/7-image.jpg", email: "jhon@dashwind.com", location: "Canada", amount: 612, date: moment().add(-3, 'd').endOf('day') },
        { name: "Matrix", avatar: "https://reqres.in/img/faces/3-image.jpg", email: "matrix@dashwind.com", location: "London", amount: 631, date: moment().add(-3, 'd').endOf('day') },
        { name: "Virat", avatar: "https://reqres.in/img/faces/2-image.jpg", email: "ereena@dashwind.com", location: "Tokyo", amount: 151, date: moment().add(-3, 'd').endOf('day') },
        { name: "Ereena", avatar: "https://reqres.in/img/faces/3-image.jpg", email: "virat@dashwind.com", location: "Paris", amount: 617, date: moment().add(-3, 'd').endOf('day') },


    ],
    TXN_REWARD_INFO_DUMMY: {
        point: {
            point_earned: 100,
            point_redeemed: 50
        },
        voucher: [
            { id: 1, name: "Voucher 1", amount: 20 },
            { id: 2, name: "Voucher 2", amount: 30 },
        ]
    },

    CAMPAIGN_DUMMIES: {
        page: 1,
        data: [
            { id: 1, name: "Campaign Name 1", description: "Campaign description", startDate: moment().endOf('day'), endDate: moment().add(365, 'd').endOf('day'), status: "ACTIVE" },
            { id: 4, name: "Campaign Name 2", description: "Campaign description", startDate: moment().add(-100, 'd').endOf('day'), endDate: moment().add(-10, 'd').endOf('day'), status: "EXPIRED" },
            { id: 6, name: "Campaign Name 3", description: "Campaign description", startDate: moment().endOf('day'), endDate: moment().add(300, 'd').endOf('day'), status: "INACTIVE" },
            { id: 28, name: "Campaign Name 4", description: "Campaign description", startDate: moment().endOf('day'), endDate: moment().add(65, 'd').endOf('day'), status: "ACTIVE" }
        ]
    },

    MEMBER_DUMMIES: {
        page: 1,
        data: [
            { phoneNo: "81002231", countryCallingCode: "65", joinDate: moment().endOf('day'), status: "ACTIVE" },
            { phoneNo: "81002232", countryCallingCode: "65", joinDate: moment().endOf('day'), status: "ACTIVE" },
            { phoneNo: "81002233", countryCallingCode: "702", joinDate: moment().endOf('day'), status: "INACTIVE" },
            { phoneNo: "81002234", countryCallingCode: "65", joinDate: moment().endOf('day'), status: "LOCKED" },
            { phoneNo: "81002235", countryCallingCode: "65", joinDate: moment().endOf('day'), status: "ACTIVE" }
        ]
    },

    PROGRAM_DUMMIES: {
        "programs": [
            {
                "description": "daily rewards",
                "program_id": 1,
                "program_name": "Daily",
                "start_date": "2023-12-08",
                "end_date": "2024-01-08",
                "rewards": {
                    "POINTS": {
                        "rule_attributes": {
                            "min_amount_spend": 10,
                            "issue_type": "variable",
                            "points": 10
                        }
                    }
                },
                "is_active": true
            },
            {
                "description": "daily rewards yeah",
                "program_id": 2,
                "program_name": "Daily",
                "start_date": "2023-12-08",
                "end_date": "2025-01-08",
                "rewards": {
                    "POINTS": {
                        "rule_attributes": {
                            "min_amount_spend": 1,
                            "issue_type": "variable",
                            "points": 50
                        }
                    }
                },
                "is_active": true
            }
        ],
        "total_pages": 1,
        "total_elements": 2
    },
    METRICS_DUMMIES: {
        "members": {
            "total": 1234,
            "new_this_month": 100
        },
        "transactions": {
            "total": 5678,
            "average_amount": 25.43
        },
        "points": {
            "issued": 9876,
            "redeemed": 3456
        }
    },

});
