import { configureStore } from '@reduxjs/toolkit'
import headerSlice from '../features/common/headerSlice'
import modalSlice from '../features/common/modalSlice'
import rightDrawerSlice from '../features/common/rightDrawerSlice'
import membersSlice from '../features/members/memberSlice'
import programsSlice from '../features/programs/slices/programSlice'
import transactionsSlice from '../features/transactions/transactionSlice'
import merchantProfileSlice from '../features/settings/profilesettings/profileSlice'
import dashboardSlice from '../features/dashboard/slices/dashboardSlice'
import transactionRewardInfoSlice from '../features/transactionRewardInfo/slices/transactionRewardInfoSlice'

const combinedReducer = {
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,
  member: membersSlice,
  program: programsSlice,
  transaction: transactionsSlice,
  transactionRewardInfo: transactionRewardInfoSlice,
  merchantProfile: merchantProfileSlice,
  dashboard: dashboardSlice,
}

export default configureStore({
  reducer: combinedReducer
})