import axios from "axios"
import { jwtDecode } from "jwt-decode"

const checkAuth = () => {
  /*  Getting token value stored in localstorage, if token is not present we will open login page 
      for all internal dashboard routes  */
  const TOKEN = localStorage.getItem("token")
  const PUBLIC_ROUTES = ["login", "forgot-password", "register", "documentation", "doc"]

  const isPublicPage = PUBLIC_ROUTES.some(r => window.location.href.includes(r))

  if ((!TOKEN || isTokenExpired(TOKEN)) && !isPublicPage) {
    if (TOKEN) {
      localStorage.clear();
    }
    window.location.href = '/login'
    return;
  } else {
    axios.defaults.headers.common['Authorization'] = `Bearer ${TOKEN}`

    axios.interceptors.request.use(function (config) {
      // UPDATE: Add this code to show global loading indicator
      document.body.classList.add('loading-indicator');

      console.log('[Request]: ', {
        url: config.url,
        method: config.method,
        data: config.data,
        headers: config.headers,
      })

      return config
    }, function (error) {
      return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
      // UPDATE: Add this code to hide global loading indicator
      document.body.classList.remove('loading-indicator');

      console.log('[Response]: ', response)

      return response;
    }, function (error) {
      document.body.classList.remove('loading-indicator');
      return Promise.reject(error);
    });
    return TOKEN
  }
}

// create a method to check if token is expired
const isTokenExpired = (token) => {
  const decodedToken = token ? jwtDecode(token) : null

  if (decodedToken && decodedToken.exp < Date.now() / 1000) {
    return true
  } else {
    return false
  }
}

export default checkAuth